import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Base64 } from 'js-base64';

type useMobileAppReturn = {
  isMobileApp: boolean;
  token?: string;
  closeWebview: () => void;
  logoutMobileApp: () => void;
  openLink: (url: string) => void;
};

export const useMobileApp = (): useMobileAppReturn => {
  const router = useRouter();
  const windowNotUndefined = typeof window !== 'undefined';
  const [isMobileApp, setIsMobileApp] = useState(false);
  const [token, setToken] = useState<string | undefined>(undefined);

  const closeWebview = () => {
    const event = new Event('close-webview');
    window.dispatchEvent(event);
  };

  const logoutMobileApp = () => {
    const event = new Event('logout-mobile-app');
    window.dispatchEvent(event);
  };

  const openLink = (url: string) => {
    if (!url) return;
    const event = new CustomEvent('open-link', {
      detail: { url },
    });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    if (router) {
      const { query } = router;
      let { mobile_app } = query;
      const { fer } = query;
      const encodedToken = fer?.toString() || '';

      try {
        const urlDecodedToken = decodeURIComponent(encodedToken);
        const decodedToken = Base64.decode(urlDecodedToken);
        const session_token = decodedToken.slice(0, decodedToken.length / 2);

        if (windowNotUndefined) {
          if (mobile_app !== undefined) {
            window.localStorage.setItem('mobile_app', mobile_app as string);
          } else {
            mobile_app = window.localStorage.getItem('mobile_app');
          }

          if (session_token !== undefined) {
            setToken(session_token);
          } else {
            setToken('');
          }
        }

        setIsMobileApp(!!mobile_app);
      } catch (error) {
        console.error('Error decoding token:', error);
        setToken('');
        setIsMobileApp(false);
      }
    }
  }, [router, windowNotUndefined]);

  return {
    isMobileApp,
    token,
    closeWebview,
    logoutMobileApp,
    openLink,
  };
};
