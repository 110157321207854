import { _PAGING_PARAMS, _PAGING_VARIABLES } from '../_paging';

export const _SHIFTS_PARAMS = `
  $excludeStatus: [String]
  $excludeUserWorkershiftStatus: [String]
  $hasUnfinishedPaperwork: Boolean
  $id: Int
  $isRetailerApproved: Boolean
  $limit: Int
  $locations: [String]
  $locationTags: [String]
  $market: Int
  $markets: [Int]
  $requestType: [String]
  $retailerCategories: [String]
  $retailers: [String]
  $retailerSizes: [String]
  $since: Date
  $sinceDatetime: DateTime
  $status: [String]
  $tags: [String]
  $to: Date
  $toDatetime: DateTime
  $users: [String]
  $userWorkershiftStatus: [String]
  $userVisibility: String
  $uuid: String
  $uuids: [String]
  $workershiftStatus: [String]
  ${_PAGING_PARAMS}
`;

export const _SHIFTS_VARIABLES = `
  excludeStatus: $excludeStatus
  excludeUserWorkershiftStatus: $excludeUserWorkershiftStatus
  hasUnfinishedPaperwork: $hasUnfinishedPaperwork
  id: $id
  isRetailerApproved: $isRetailerApproved
  limit: $limit
  locations: $locations
  locationTags: $locationTags
  market: $market
  markets: $markets
  requestType: $requestType
  retailerCategories: $retailerCategories
  retailers: $retailers
  retailerSizes: $retailerSizes
  since: $since
  sinceDatetime: $sinceDatetime
  status: $status
  tags: $tags
  to: $to
  toDatetime: $toDatetime
  users: $users
  userWorkershiftStatus: $userWorkershiftStatus
  userVisibility: $userVisibility
  uuid: $uuid
  uuids: $uuids
  workershiftStatus: $workershiftStatus
  ${_PAGING_VARIABLES}
`;
